import React from "react"
import ListItem from "./listItem"

const entertainment = [
  {
    name: "PewDiePie",
    subs: "96.8M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79FVckie4j9WT-4cEW6iu3gPd4GivQf_XNSWg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC-lHJZR3Gqxm24_Vd_AJ5Yw",
    rank: 1,
  },
  {
    name: "SET India ",
    subs: "50.3M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_rrvu2vunZ4PEZ0fXnz78RaMK11j-Qrv1KZw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCpEhnqL0y41EpW2TvWAHD7Q",
    rank: 2,
  },
  {
    name: "Justin Bieber ",
    subs: "45.5M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_J6Yd47Wjo8mxFwihVvPrMseioDo1n5aIXAw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCYWOjHweP2V-8kGKmmAmQJQ",
    rank: 3,
  },
  {
    name: "Badabun",
    subs: "40.4M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78x3T7ChRLSqiVCXCphxYL7nBPfaSERNevWHw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCYWOjHweP2V-8kGKmmAmQJQ",
    rank: 4,
  },
  {
    name: "HolaSoyGerman",
    subs: "39.4M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_N9uLl8iqg11SKM61oDY2a6ZYdczHmiIBYBw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCZJ7m7EnCNodqnu5SAtg8eQ",
    rank: 5,
  },
  {
    name: "JuegaGerman",
    subs: "35.4M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78r93JtUd0J2x3vDRRkIrBObCLOY7wytiN3JQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCYiGq8XF7YQD00x7wAd62Zg",
    rank: 6,
  },
  {
    name: "elrubiusOMG",
    subs: "35.1M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78TJZPSsMrUf2cCoAW0Jp-c02oNgxAKAfLTyg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCXazgXDIYyWH-yXLAkcrFxw",
    rank: 7,
  },
  {
    name: "Katy Perry",
    subs: "34.1M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_J2Wfp1KTYcGILVs6o-bS8gQ7SpD19WkRUPg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCYvmuw-JtVrTZQ-7Y4kd63Q",
    rank: 8,
  },
  {
    name: "Felipe Neto",
    subs: "33M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79S4FrOUo9crQs2nxlwuJKOeg4O5chZcaB6jA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCV306eHqgo0LvBf3Mh36AHg",
    rank: 9,
  },
  {
    name: "TheEllenShow",
    subs: "33M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-vAW-s0xZI7oURqwN9_hHXlEU9W0Fy2lYtqw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCp0hYYBW6IMayGgR-WeoCvQ",
    rank: 10,
  },
  {
    name: "Rihanna",
    subs: "32.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78cE8jb3vGeUg8DjlF35A_BUx0Gxhx2YIw6wA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCcgqSM4YEo5vVQpqwN-MaNw",
    rank: 11,
  },
  {
    name: "Zee TV",
    subs: "31.1M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-hmOKbj0GCmESo33aNv0LU7xRD0179m--7BA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCppHT7SZKKvar4Oc9J4oljQ",
    rank: 12,
  },
  {
    name: "✿ Kids Diana Show",
    subs: "30.3M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l788z_ZUqXrqW8qLNO-zITrOJmGlAjZiqIvVjg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCk8GzjMOrta8yxDcKfylJYw",
    rank: 13,
  },
  {
    name: "YouTube",
    subs: "28M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79Dpy9yGG-j3hTwtQiN3iXRVaC0UHu2cgSUvQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCBR8-60-B28hp2BmDPdntcQ",
    rank: 14,
  },
  {
    name: "Você Sabia?",
    subs: "25.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_2r3puQ0lJGTyDh8WGHpxG8XRCnXNyv2PE2A=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCj0O6W8yDuLg3iraAXKgCrQ",
    rank: 15,
  },
  {
    name: "Luis Fonsi",
    subs: "25.6M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78PTpMiwr6ypA57wLB80I6l81L8jEKTFBF3lQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCxoq-PAQeAdk_zyg8YS0JqA",
    rank: 16,
  },
  {
    name: "Get Movies",
    subs: "25.1M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79JLX6VIS2eErsX2iWluQVMkQuJXCEN2dHQeA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UClZkHt2kNIgyrTTPnSQV3SA",
    rank: 17,
  },
  {
    name: "ToyPudding TV",
    subs: "25M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-1D7hB_0rOExGjMxCzka-BffGT2-E86t79kA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCKqx9r4mrFglauNBJc1L_eg",
    rank: 18,
  },
  {
    name: "LUCCAS NETO - LUNES",
    subs: "25M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-91wq2ox9UlIebXYVL40aWl5iPihhzkNQt_A=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC_gV70G_Y51LTa3qhu8KiEA",
    rank: 19,
  },
  {
    name: "WorkpointOfficial",
    subs: "24M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_2rWk0_6TFkoRmJTkukAR5mxETWtRn8Oz8Yw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC3ZkCd7XtUREnjjt3cyY_gg",
    rank: 20,
  },
  {
    name: "rezendeevil",
    subs: "23.9M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_KMwrR6_LcbJYIwjVKa8UC5usvdPZczTsiEg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCbTVTephX30ZhQF5zwFppBg",
    rank: 21,
  },
  {
    name: "Vlad and Nikita",
    subs: "23.3M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78TSx-25XD6ClqXQDYI9Z4gn6Rq9eON1UCoFQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCvlE5gTbOvjiolFlEm-c_Ow",
    rank: 22,
  },
  {
    name: "nigahiga",
    subs: "21.3M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_bo6vxoQkbnFsQnU5Jt-1ZLZHlV_U-A7EByg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCSAUGyc_xA8uYzaIVG6MESQ",
    rank: 23,
  },
  {
    name: "MrBeast",
    subs: "21.1M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79GuzIhiArE0EfFD8lHukJAj6cw_xcgwtvChA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCX6OQ3DkcsbYNE6H8uQQuVA",
    rank: 24,
  },
  {
    name: "SAB TV",
    subs: "20.8M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78Fqw2O_7LmBzq4fKRx7T6P8tXAt7bxCRpfvg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC6-F5tO8uklgE9Zy8IvbdFw",
    rank: 25,
  },
  {
    name: "WatchMojo.com",
    subs: "20.8M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-FJiGc3LKz1NA5Eonx-zHWNRr2Np1JjbpEog=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCaWd5_7JhbQBe4dknZhsHJg",
    rank: 26,
  },
  {
    name: "KSI",
    subs: "20.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-bgK6nBn1NYWSPqZ__AbCEB_mPxhSVW-2Blw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCVtFOytbRpEvzLjvqGG5gxQ",
    rank: 27,
  },
]

const Entertainment = () => <ListItem data={entertainment} />

export default Entertainment
